$imgix: 'https://ht-prod.imgix.net';
@use '../../../styles/colors' as colors;
@use '../../../styles/variables' as variables;

section.n4-pros-and-cons {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 3rem 0 3rem;
  flex-shrink: 0;
  max-width: 810px;

  &--column {
    margin: 3rem 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 640px;

    @media #{variables.$lg} {
      // padding: 0 10rem;
    }

    @media #{variables.$xs, variables.$md} {
      padding: 0;
    }
  }

  @media #{variables.$xs, variables.$md} {
    max-width: 100%;
    min-width: 100%;

    span {
      font-weight: 400 !important;
    }
  }

  // @media #{variables.$lg} {
  //   padding: 0 2rem;
  // }

  // &--column {
  //   padding: 0 2rem;
  //   @media #{variables.$xs, variables.$md} {
  //     padding: 0;
  //   }

  //   @media #{variables.$lg} {
  //     padding: 0 10rem;
  //   }
  // }

  div.container {
    display: flex;
    align-self: flex-start;
    gap: 1.5rem;
    width: 100%;

    &--column {
      flex-direction: column;
      gap: 3rem;
    }

    @media #{variables.$xs} {
      flex-direction: column;
      gap: 3rem;
      width: 100%;
      padding: 0;
    }
  }

  section.column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.875rem;
    flex: 1 0 0;

    @media #{variables.$xs} {
      gap: 1.25rem;
      min-width: 24.75rem;
    }

    h4 {
      color: colors.$navy-blue;
      font-family: var(--hankenGrotesk-font);
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: 2.1rem;
      letter-spacing: -0.0175rem;
      margin-bottom: 0;

      @media #{variables.$xs} {
        font-size: 1.5rem;
        font-weight: 550;
        line-height: 1.8rem;
        letter-spacing: -0.015rem;
      }
    }

    dl {
      margin-top: 3px;
      @media #{variables.$xs} {
        margin-top: 5px;
      }
      dt {
        font-weight: 500;
        margin-top: -0.5rem;
      }
      dd {
        font-weight: 300;
        @media #{variables.$xs} {
          font-weight: 400;
          line-height: 1.6;
          //font-size: 17px;
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 0;
      padding: 0;

      li {
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;
        margin-bottom: 1.88rem;

        div.icon {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2.5rem;
          min-width: 2.188rem;
          min-height: 2.188rem;

          @media #{variables.$xs} {
            min-width: 2.125rem;
            min-height: 2.125rem;
          }

          i {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
          }

          &--success {
            border: 1px solid colors.$success;

            i {
              left: 0.125rem;
              filter: invert(31%) sepia(62%) saturate(2596%) hue-rotate(117deg) brightness(101%) contrast(82%);
            }
          }

          &--error {
            border: 1px solid colors.$error;

            i {
              left: 0.313rem;
              filter: invert(22%) sepia(99%) saturate(2361%) hue-rotate(350deg) brightness(96%) contrast(92%);
            }
          }
        }

        section {
          display: flex;
          flex-direction: column;
          align-self: center;

          @media #{variables.$xs} {
            gap: 0.5rem;
          }

          strong {
            color: colors.$navy-blue;
            font-family: var(--hankenGrotesk-font);
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 550;
            line-height: 2.1rem;
            letter-spacing: -0.0125rem;
            align-self: stretch;

            @media #{variables.$xs} {
              margin-top: 5px;
              font-size: 1.225rem;
              font-weight: 650;
              line-height: 1.6875rem;
              letter-spacing: -0.01125rem;
            }
          }

          span {
            color: colors.$navy-blue;
            font-family: var(--hankenGrotesk-font);
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 300;
            line-height: 1.7rem;
            letter-spacing: -0.0125rem;
          }
        }
      }

      li:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
