$imgix: 'https://ht-prod.imgix.net';
@use '../../../../styles/variables' as variables;

section.panel-tab {
  max-height: 25.5rem;
  overflow-y: auto;
  display: grid;
  // grid fills data in by rows not columns, this allows data to fill in by columns
  grid-template-rows: 1fr 1fr 1fr;
  &--large {
    grid-template-rows: none;
  }
  grid-auto-flow: column;
  justify-content: stretch;
  width: 100%;
  align-content: start;
  column-gap: 2rem;
  row-gap: 0rem;
  @media (min-width: 1200px) {
    row-gap: 1.5rem;
  }

  @media #{variables.$xs, variables.$md, variables.$lg} {
    // switch to fill in by row and scroll for sizes that don't fit more than three
    grid-template-rows: unset;
    grid-auto-flow: unset;
    width: unset;
    justify-content: center;
    grid-template-columns: minmax(0, 30rem);
    grid-auto-rows: minmax(7.5rem, auto);
  }

  @media #{variables.$xs} {
    max-height: calc(100vh - 18rem);
  }

  @media #{variables.$sm, variables.$md} {
    max-height: 36rem;
    overflow-y: auto;
  }

  @media #{variables.$xs, variables.$md} {
    grid-template-columns: unset;
    width: 100%;
    justify-content: flex-start;
    overflow-y: auto;

    &--products {
      row-gap: 1rem;
      @media (min-width: 1200px) {
        row-gap: 1.5rem;
      }
      grid-auto-rows: minmax(4.3125rem, auto);
    }
  }
}
