$imgix: 'https://ht-prod.imgix.net';
@use '../../../../styles/variables' as variables;
@use '../../../../styles/colors' as colors;

section.custom {
  display: contents;

  &--cta {
    display: contents;

    &--row {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;

      figure.image {
        position: relative;
        width: 100%;
        height: 29.75rem;
        display: flex;
        border-radius: 1.25rem;
        background-color: lightgray;
        overflow: hidden;
        margin: 0;

        img {
          object-fit: cover;
          width: max-content;
          height: max-content;

          @media #{variables.$xs, variables.$md, variables.$lg, variables.$laptop} {
            height: 100%;
          }
        }

        div.skeleton {
          position: absolute;
          display: flex;
          width: 100%;
          height: 100%;
        }
      }

      section.content {
        border-radius: 1.25rem;
        overflow: hidden;
        position: absolute;
        justify-content: flex-end;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-self: stretch;
        z-index: 2;
        max-width: 27.5rem;
        padding: 1.5rem;
        gap: 2rem;
        height: 100%;

        section.content-data {
          align-self: stretch;
          display: flex;
          width: 100%;
          flex-direction: column;
          justify-items: flex-end;
          height: fit-content;
          gap: 2rem;
          border-radius: 1.25rem;
          background-color: colors.$white;
          padding: 1.5rem;

          section.about {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1.5rem;
            align-self: stretch;

            span.title {
              color: colors.$navy-blue;
              font-family: var(--hankenGrotesk-font);
              font-size: 2rem;
              font-style: normal;
              font-weight: 500;
              line-height: 2.4rem;
              letter-spacing: -0.06rem;
            }

            p.description {
              color: colors.$navy-blue;
              font-family: var(--hankenGrotesk-font);
              font-size: 1.125rem;
              font-style: normal;
              font-weight: 400;
              line-height: 1.6875rem;
              letter-spacing: -0.03375rem;
              padding: 0;
              margin: 0;
            }
          }

          section.action {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 1rem;

            span {
              color: colors.$navy-blue;
              font-family: var(--hankenGrotesk-font);
              font-size: 1rem;
              font-style: normal;
              font-weight: 400;
              line-height: 1.6875rem;
              letter-spacing: -0.03375rem;
            }

            section.offer {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 100%;
              width: 3.5rem;
              height: 3.5rem;
              background: colors.$navy-blue;

              i {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                width: 1rem;
                height: 1.188rem;
                filter: invert(95%) sepia(100%) saturate(0%) hue-rotate(78deg) brightness(104%) contrast(106%);
              }
            }
          }
        }
      }
    }

    &--column {
      position: relative;
      width: 100%;

      section.container {
        position: absolute;
        top: 4rem;
        display: flex;
        padding: 0.75rem;
        align-items: flex-start;
        gap: 1rem;
        height: 12.5rem;
        width: 100%;
        border-radius: 1.25rem;
        background: colors.$light-green-200;
        min-width: none;
        @media (min-width: 360px) {
          min-width: 320px;
        }
        @media (min-width: 400px) {
          min-width: 355px;
        }
        @media #{variables.$xs, variables.$md} {
          height: 11.5rem;
          max-width: 100%;
          top: 0;
        }

        figure.image {
          position: relative;
          display: flex;
          border-radius: 1.25rem;
          width: 100%;
          aspect-ratio: 199/175;
          align-self: center;
          min-width: 12.438rem;
          max-width: 12.438rem;
          max-height: 10.9375rem;
          overflow: hidden;
          margin: 0;
          background-color: lightgray;

          @media #{variables.$xs, variables.$md} {
            align-self: flex-start;
            aspect-ratio: 1;
            max-height: 10rem;
            min-width: 8rem;
            max-width: 10rem;
          }

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        section.content {
          display: flex;
          padding: 0.5rem;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          align-self: stretch;
          width: 100%;

          section.about {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 0.375rem;
            align-self: stretch;
            max-width: 15rem;

            @media #{variables.$xs, variables.$md} {
              max-width: 100%;
            }

            span.title {
              color: colors.$navy-blue;
              font-family: var(--hankenGrotesk-font);
              font-size: 1.25rem;
              font-style: normal;
              font-weight: 500;
              line-height: 1.5rem;
              letter-spacing: -0.0375rem;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            p.description {
              padding: 0;
              margin: 0;
              color: colors.$navy-blue;
              font-family: var(--hankenGrotesk-font);
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 400;
              line-height: 1.225rem;
              letter-spacing: -0.02625rem;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }

          section.action {
            display: flex;
            align-self: flex-end;
            align-items: center;
            gap: 1rem;

            span {
              color: colors.$navy-blue;
              font-family: var(--hankenGrotesk-font);
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 400;
              line-height: 1.225rem;
              letter-spacing: -0.02625rem;
            }

            section.offer {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 100%;
              width: 2.5rem;
              height: 2.5rem;
              background: colors.$navy-blue;

              i {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                width: 1rem;
                height: 1.188rem;
                filter: invert(95%) sepia(100%) saturate(0%) hue-rotate(78deg) brightness(104%) contrast(106%);
              }
            }
          }
        }
      }
    }
  }

  &--non-cta {
    &--row {
      display: flex;
      max-width: 19.625rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.1875rem;
      overflow: hidden;

      figure.image {
        margin: 0;
        position: relative;
        display: flex;
        justify-content: center;
        overflow: hidden;
        aspect-ratio: 314/204;
        border-radius: 0.813rem;
        max-width: 19.625rem;
        max-height: 204px;
        width: 100%;
        height: 100%;

        div.skeleton {
          position: absolute;
          display: flex;
          width: 100%;
          height: 100%;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        div.icon {
          position: absolute;
          bottom: 0.5rem;
          right: 0.5rem;
          z-index: 2;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          text-align: center;
          border: none;
          border-radius: 100%;
          background-color: colors.$white;
          box-shadow: 4px 4px 32px -2px rgba(0, 0, 0, 0.15);
          width: 2.5rem;
          height: 2.5rem;

          i {
            width: 1rem;
            height: 1.188rem;
            filter: invert(13%) sepia(47%) saturate(1050%) hue-rotate(183deg) brightness(90%) contrast(96%);
          }
        }
      }

      section.details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.375rem;

        h4 {
          padding: 0;
          margin: 0;
          color: colors.$navy-blue;
          font-family: var(--hankenGrotesk-font);
          flex-wrap: wrap;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.4625rem;
          letter-spacing: -0.03375rem;
          @media #{variables.$xs, variables.$md} {
            -webkit-line-clamp: 1;
            font-weight: 500;
            font-size: 1.25rem;
          }
          @media #{variables.$lg} {
            font-size: 0.875rem;
          }

          @media #{variables.$laptop} {
            font-size: 1.125rem;
          }
        }

        p {
          padding: 0;
          margin: 0;
          color: colors.$navy-blue-800;
          font-family: var(--hankenGrotesk-font);
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.225rem;
          letter-spacing: -0.02625rem;
          overflow-y: auto;
          max-height: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    &--column {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: flex-start;
      justify-content: stretch;
      max-width: 29.938rem;
      max-height: 7.5rem;
      gap: 1.1875rem;
      overflow-y: hidden;

      @media #{variables.$xs, variables.$md} {
        max-width: 100%;
        max-height: 6rem;
      }

      figure {
        margin: 0;
        position: relative;
        display: flex;
        justify-content: center;
        overflow: hidden;
        aspect-ratio: 144/108;
        max-height: 7.5rem;
        border-radius: 0.625rem;
        min-width: 10rem;
        max-width: 10rem;
        border-radius: 0.813rem;

        @media #{variables.$xs, variables.$md} {
          aspect-ratio: 128/96;
          max-height: 6rem;
          min-width: 8rem;
          max-width: 8rem;
        }

        section.skeleton {
          position: absolute;
          display: flex;
          width: 100%;
          height: 100%;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        div.placeholder {
          min-width: 10rem;
          max-width: 10rem;
          border-radius: 0.813rem;
          background-color: colors.$navy-blue-200;

          @media #{variables.$xs, variables.$md} {
            aspect-ratio: 128/96;
            max-height: 6rem;
            min-width: 8rem;
            max-width: 8rem;
          }
        }

        div.icon {
          position: absolute;
          bottom: 0.5rem;
          right: 0.5rem;
          z-index: 2;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          text-align: center;
          border: none;
          border-radius: 100%;
          background-color: colors.$white;
          box-shadow: 4px 4px 32px -2px rgba(0, 0, 0, 0.15);
          width: 2.5rem;
          height: 2.5rem;

          i {
            width: 1rem;
            height: 1.188rem;
            filter: invert(13%) sepia(47%) saturate(1050%) hue-rotate(183deg) brightness(90%) contrast(96%);
          }
        }

        div.vs {
          position: absolute;
          top: 2.813rem;
          z-index: 2;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          text-align: center;
          border: none;
          border-radius: 100%;
          background-color: colors.$primary-blue;
          box-shadow: 4px 4px 32px -2px rgba(0, 0, 0, 0.15);
          width: 1.938rem;
          height: 1.938rem;

          @media #{variables.$xs, variables.$md} {
            top: 2.125rem;
          }

          span {
            color: colors.$white;
            text-align: center;
            font-family: var(--hankenGrotesk-font);
            font-size: 0.83731rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1rem;
            letter-spacing: -0.00838rem;
            padding-bottom: 0.125rem;
          }
        }
      }

      section.details {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

        &--gap {
          justify-content: unset;
          gap: 0.5rem;
        }

        h4 {
          padding: 0;
          margin: 0;
          color: colors.$navy-blue;
          font-family: var(--hankenGrotesk-font);
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.4rem;
          letter-spacing: -0.03rem;
          flex-wrap: wrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          @media #{variables.$xs, variables.$md} {
            -webkit-line-clamp: 1;
            font-weight: 500;
            font-size: 1.25rem;
          }
        }

        div.date {
          display: flex;
          color: colors.$primary-blue;
          font-family: var(--hankenGrotesk-font);
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.225rem;
          letter-spacing: -0.02625rem;
        }

        p {
          padding: 0;
          margin: 0;
          color: colors.$navy-blue-600;
          font-family: var(--hankenGrotesk-font);
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.225rem;
          letter-spacing: -0.02625rem;
          overflow-y: auto;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}

div.wrapper {
  display: contents;

  a {
    display: flex;
  }
}
